<template>
    <div class="container-fluid px-0 py-2 mt-5" style="margin-bottom: 100px">
        <div class="mt-5 ml-5 d-flex">
            <div class="col-6">             
            <router-link :to="{ name: 'Order' }">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-arrow-left"
                    style="opacity: 0.5"
                >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                </svg>
            </router-link>
        </div>
        <div class="col-6 text-right">             
            <button
                type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                class="font-14 ml-3 font-b-5 hidde print-order btn btn-info mr-2"
                v-if="isAdmin"
                >
                    Ações
            </button>

          <!-- Modal -->
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Ações</h5>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body d-flex justify-content-center">
                  <button
                      type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                      class="font-14 ml-3 font-b-5 hidde print-order btn btn-info mr-2"
                      @click="print()"
                      v-if="isAdmin"
                      >
                          Imprimir pedido
                  </button>  

              <b>ou</b> 

             <button
                      type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                      class="font-14 ml-3 font-b-5 hidde print-order btn btn-info mr-2"
                      @click="downloadPdf()"
                      v-if="isAdmin"
                      >
                          Baixar pedido
                  </button>  
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                </div>
              </div>
            </div>
          </div>      

            <button
                    type="submit"
                    class="btn btn-dark ml-4 font-14 font-b-5 hidde"
                    style="background-color: #000"
                    @click="cancelOrder"
                    v-if="isAdmin"
                  >
                    Cancelar pedido
            </button>
          </div>
      </div>
       
      <div class="col-12" id="print-order">

        <div class="p-dash-cliente-master">
          <div class="col-12 col-md-12 mx-auto">
            <div class="row">
              <div class="col-12 cancela-col-padding" v-if="!loading && custom">
                <div class="container-pedido">
                  <div class="left-side">
                    <img 
                      v-if="custom.enterprise"
                      :src="custom.enterprise.image"
                      class="img-fluid logo"
                      title="Logo"
                      style="max-height: 40px"
                    />
  
                    <h4>DETALHES DO PEDIDO</h4>
  
                    <CustomItemUser :custom="custom" />
                  </div>
                  <div class="right-side">
                    <div class="header-custom">
                      <!-- <h4>Minha personalização</h4> -->
                      <div class="total">
                        <span>TOTAL PEDIDO</span>
                        <p class="text-muted">R$ {{ formatMoney(custom.price) }}</p>
                      </div>
                      
                    </div>
                    <div v-if="!loading_itens">
                      <div v-if="itens_personalizations.length">
                        <div
                          data-anima="bottom"
                          class="itens-pedido"
                          v-for="(item_custom, index) in itens_personalizations"
                          :key="item_custom.id"
                        >
                          <h5>
                            {{
                              item_custom.trigger_name
                                ? item_custom.trigger_name
                                : `PERSONALIZAÇÃO ${index + 1}`
                            }}
                          </h5>
  
                          <div class="item-pedido">
                            <img
                              :src=" item_custom.custom_item.image3"
                              class="img-item" 
                              alt=""
                            />
                            <!-- <img
                              :src="
                                item_custom.custom_item.image
                              "
                              alt=""
                              class="img-pedra"
                            /> -->
                            <div class="info-itens">
                              <div class="info-item">
                                <div class="item-dado">
                                  <span>PERSONALIZAÇÃO</span>
                                  <p>{{ item_custom.personalization_type.name }}</p>
                                </div>
                                <div class="item-dado">
                                  <span>TIPO </span>
                                  <p>
                                    {{
                                       item_custom.custom_item.name
                                    }}
                                  </p>
                                </div>
                              </div>
                             <!-- <p class="obs-item">
                                {{
                                  item_custom.trigger
                                    ? item_custom.trigger.subtitle
                                    : `-`
                                }}
                              </p> -->
                            </div>
                            <div>
                              <p class="price-item text-muted">
                                R$
                                {{
                                  formatMoney(
                                     item_custom.custom_item.price
                                  )
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="itens-pedido"
                          v-for="kit_item in custom.kits"
                          :key="kit_item.kit.id"
                        >
                          <h5>
                            {{ kit_item.kit.name }}
                          </h5>
  
                          {{ kit_item.kit.gallery }}
  
                          <div class="item-pedido">
                            <img
                              v-if="kit_item.kit"
                              :src="kit_item.kit.image || ''"
                              class="img-item"
                              alt=""
                            />
                            <div class="info-itens">
                              <div class="info-item">
                                <div class="item-dado">
                                  <span>DETALHES</span>
                                  <p
                                    v-for="(group, key) in kit_item.kit.products"
                                    :key="key"
                                  >
                                    {{ key }} - ({{
                                      group
                                        .map((elem) => {
                                          return (
                                            elem.product.quantity +
                                            " " +
                                            elem.product.name
                                          );
                                        })
                                        .join(" | ")
                                    }})
                                  </p>
                                </div>
                              </div>
                              <p class="obs-item">
                                {{ kit_item.kit.description }}
                              </p>
                            </div>
                            <div>
                              <p class="price-item text-muted">
                                R$
                                {{
                                  formatMoney(
                                    kit_item.kit ? kit_item.kit.price : 0
                                  )
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
  
                        <div class="rodape-pedido-lista">
                          <div class="item-assinatura">
                            <span>COMPRADOR</span>
                            <p>
                              {{ custom.user.first_name }}
                              {{ custom.user.last_name }}
                            </p>
                          </div>
                          <div class="item-assinatura">
                            <span>VENDEDOR</span>
                            <p>{{ custom.enterprise.name }}</p>
                          </div>                   
    
                          <div class="item-assinatura price w-100   ">
                            <span>TOTAL PEDIDO</span>
                            <p class="text-muted">R$ {{ formatMoney(custom.price) }}</p>
                          </div>

                        </div>
                      </div>
                      <div
                        v-if="!itens_personalization.length"
                        data-anima="bottom"
                        class="itens-pedido text-center mt-5"
                      >
                        Não Itens/Kits para exibir
                      </div>
                    </div>
                    <div class="col-12 cancela-col-padding" v-if="loading_itens">
                      <div class="loading">
                        <b-spinner label="Loading..."></b-spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 cancela-col-padding" v-if="loading">
                <div class="loading">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CustomItemUser from "@/components/Kit/CustomItemUser.vue";
  
  import OrderService from "@/services/resources/OrderService";
  const serviceOrder = OrderService.build();
  
  import TriggerService from "@/services/resources/TriggerService";
  const serviceTrigger = TriggerService.build();
  
  import KitConfigService from "@/services/resources/KitConfigService";
  const serviceKit = KitConfigService.build();
  
  import OrderServiceCancel from "@/services/resources/OrderServiceCancel";
  const serviceCancel = OrderServiceCancel.build();

  
  import Money from "@/mixins/money";
  
  export default {
    name: "MyCustomizationItem",
    mixins: [Money],
  
    data() {
      return {
        loading: false,
        loading_itens: false,
        custom: null,
        triggers: [],
        itens_personalization: [],
        itens_personalizations: [],
        select_itens_personalization: [],
      };
    },
    components: {
      CustomItemUser,
    },
    computed: {
      user() {
        return this.$store.getters.getProfile;
      },
      isAdmin() {
      return this.$store.getters.isAdmin;
    },
    },
  
    methods: {
      async selectKit(id) {
        return new Promise((resolve) => {
          if (!id) {
            resolve(null);
          }
  
          let data = {
            id: id,
          };
  
          serviceKit
            .read(data)
            .then((resp) => {
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              resolve(null);
            });
        });
      },
      cancelOrder() {
        let data = {
            id: this.custom.id,
        };

        serviceCancel.destroy(data).then((resp) => {
            this.$bvToast.toast("Seu pedido foi cancelado com sucesso!", {
            title: "Pedido cancelado",
            autoHideDelay: 5000,
            type: "success",
            });
            this.$router.push("/pedidos")
        });
    },
      async configItens() {
       
     
        // this.custom.itens.forEach((element) => {
        //   this.selectKit(element.kit_config_id).then((resp) => {
        //     if (resp.type == "PERSONALIZATION_TYPE") {
        //       const data = {
        //         ...resp,
        //         trigger: this.triggers
        //           ? this.triggers.itens.find((t) => t.kit_config_id == resp.id)
        //           : null,
        //       };
        //       this.itens_personalization.push(data);
        //     } else if (resp.type == "PERSONALIZATION_TYPE_ITEM") {
        //       this.select_itens_personalization.push(resp);
        //     }
        //   });
        // });
  
        // setTimeout(() => {
        //   this.loading_itens = false;
        // }, 500);
      },
      print() {
       // var $print = $("#print-order")
       // .clone()

    // Stop JS execution
      window.print();

      // Remove div once printed
    //  $print.remove();
      
       // window.print();
      },
      fetchTrigger() {
        let data = {
          tipology_id: this.custom.tipology_id,
        };
        serviceTrigger
          .search(data)
          .then((resp) => {
            this.triggers = resp.data;          

            this.configItens();           
          })
          .catch((err) => {
            console.log(err);
          });
      },
      
      downloadPdf() {
        
				let ele = document.getElementById("print-order");
        var opt = {
            margin: 0,
            filename: 'Pedido do '+this.custom.user.first_name + " "+this.custom.user.last_name+'.pdf',
            image:        { type: 'jpg', quality: 0.98 },
            html2canvas:  { scale: 2, useCORS: true },
         pagebreak: { before: '.itens-pedido', mode: ['css', 'legacy'] },
      /*pagebreak: {
      before: ".newPage",
      avoid: ["h2", "tr", "h3", "h4", ".field"]
    },*/
        // html2canvas: {
        //   scale: 2,
        //   logging: true,
        //   dpi: 192,
        //   letterRendering: true
        // },
            jsPDF:        { unit: 'mm', format: 'a3', orientation: 'portrait' }
        };
				html2pdf().set(opt).from(ele).save();
			},
      fetchCustom(id_custom) {
        this.loading = true;
        this.loading_itens = true;
        serviceOrder
          .read(this.$route.params.id)
          .then((resp) => {
            console.log(resp);
            console.log(resp.order_completa.itens);
            this.itens_personalizations = resp.order_completa.itens
          //  this.itens_personalization = resp.order_completa.itens
            this.loading_itens = false
            this.custom = resp;
            this.fetchTrigger();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
    mounted() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchCustom(this.$route.params.id);
        let script = document.createElement('script')
        script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js')
        document.head.appendChild(script)

        // let script2 = document.createElement('script')
        // script2.setAttribute('src', ' https://cdn.jsdelivr.net/npm/html2canvas@1.4.1/dist/html2canvas.min.js')
        // document.head.appendChild(script2)
      
    },
  };
  </script>
  

  <style scoped>
 .container-fluid {
    background-color: #fff !important;
  }
  .container-fluid {
    min-height: 80vh;
  }
  
  .item-info {
    font-size: 14px;
  }
  .loading {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-pedido {
    display: grid;
    grid-template-columns: 306px 1fr;
    gap: 20px;
    padding: 50px 0;
  }
  .left-side .logo {
    display: block;
    margin: 0 auto;
    font-size: 13px;
    margin-bottom: 30px;
  }
  .left-side h4 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    text-align: center;
    margin-bottom: 15px;
  }
  
  /* lado direito */
  .right-side {
    /* background: red; */
  }
  .right-side .header-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right-side .header-custom h4 {
    font-size: 22px;
    font-weight: 600;
    color: rgba(47, 47, 47, 0.7);
    margin: 0;
  }
  
  .right-side .header-custom .total span {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    letter-spacing: 2px;
    text-align: right;
    display: block;
  }
  .right-side .header-custom .total p {
    color: var(--color);
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    text-align: right;
  }
  .itens-pedido {
    /* background: red; */
  }
  .itens-pedido h5 {
    color: #acaca9;
    font-weight: 600;
    margin-left: 30px;
    margin-bottom: 10px;
    margin-top: 30px;
    text-transform: uppercase;
  }
  .item-pedido {
    background: rgba(244, 244, 246, 0.5);
    padding: 30px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 4fr 2fr; */
  }
  .item-pedido .img-item {
    max-width: 140px;
    height: 140px;
    border-radius: 16px;
  }
  
  .item-pedido .img-pedra {
    max-width: 80px;
    height: 80px;
    border-radius: 10px;
  }
  .info-itens .info-item {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* gap: 20px; */
    gap: 10px;
  }
  .info-itens .info-item .item-dado {
    width: 100%;
  }
  .info-itens .info-item .item-dado span {
    font-size: 11px;
    font-weight: 600;
    color: rgba(47, 47, 47, 0.4);
  }
  .info-itens .info-item .item-dado p {
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  .info-itens .obs-item {
    color: rgba(47, 47, 47, 0.4);
    font-weight: 500;
    font-size: 13px;
    max-width: 80%;
    margin-top: 7px;
  }
  .item-pedido .price-item {
    color: var(--color);
    font-weight: 600;
    font-size: 22px;
    text-align: right;
    min-width: 160px;
  }
  .rodape-pedido-lista {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 60px 30px;
    gap: 80px;
  }
  .rodape-pedido-lista .item-assinatura {
    border-top: 1px solid #83838365;
    padding-top: 15px;
  }
  .rodape-pedido-lista .item-assinatura.price {
    border: none;
    text-align: right;
  }
  .rodape-pedido-lista .item-assinatura span {
    color: #9e9e9a;
    font-size: 13px;
  }
  .rodape-pedido-lista .item-assinatura p {
    color: rgba(46, 46, 46, 0.8);
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
    font-weight: 600;
  }
  .rodape-pedido-lista .item-assinatura.price p {
    color: var(--color);
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    .cancela-col-padding {
      padding: 0 !important;
    }
    .container-pedido {
      /* display: block; */
      grid-template-columns: 1fr;
      padding: 30px 0;
    }
    .left-side .logo {
      margin: 0;
      max-height: 30px !important;
    }
    .left-side h4 {
      text-align: left;
      font-size: 12px;
      margin-top: 20px;
    }
    .right-side .header-custom {
      display: block;
    }
    .right-side .header-custom h4 {
      font-size: 16px;
      margin-bottom: 15px;
    }
  
    .right-side .header-custom .total span {
      font-size: 12px;
      letter-spacing: 0;
      text-align: left;
    }
    .right-side .header-custom .total p {
      font-size: 22px;
      text-align: left;
    }
    .itens-pedido h5 {
      font-size: 14px;
      margin-left: 0;
    }
    .item-pedido {
      padding: 20px;
      border-radius: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    .item-pedido .img-item {
      max-width: 120px;
      height: 120px;
      border-radius: 8px;
    }
  
    .item-pedido .img-pedra {
      max-width: 60px;
      height: 60px;
      border-radius: 8px;
    }
  
    .info-itens .info-item .item-dado span {
      font-size: 10px;
    }
    .info-itens .info-item .item-dado p {
      font-size: 13px;
    }
    .info-itens .obs-item {
      font-size: 12px;
      max-width: 100%;
      margin: 0;
    }
    .item-pedido .price-item {
      font-size: 18px;
      text-align: left;
      min-width: 100%;
    }
    .rodape-pedido-lista {
      grid-template-columns: 1fr;
      align-items: center;
      padding: 50px 0px;
      gap: 50px;
    }
    .rodape-pedido-lista .item-assinatura {
      padding-top: 10px;
    }
    .rodape-pedido-lista .item-assinatura.price {
      text-align: left;
    }
    .rodape-pedido-lista .item-assinatura span {
      color: #9e9e9a;
      font-size: 12px;
    }
    .rodape-pedido-lista .item-assinatura p {
      font-size: 14px;
    }
    .rodape-pedido-lista .item-assinatura.price p {
      color: var(--color);
      font-size: 22px;
    }
  }



  </style>

  <style>
        
@media print {

body, body * {
 visibility:hidden !important;
}

.left-sidebar, .left-sidebar *{
  visibility:hidden !important;
}

.topbar, .topbar * {
  visibility:hidden;
}

.sidebar-nav * {
  visibility:hidden !important;
}

.img-fluid, .img-fluid * {
  visibility: hidden;
}


.mainside, .mainside *  {
  visibility: hidden !important;
  display: none !important;
}

#sidebarnav,  #sidebarnav *{
  visibility:hidden;
}


.navbar-header, .bder-navbar {
  visibility: hidden !important;
}

.print, .print * {
    visibility: hidden !important;
}

#print-order, #print-order * {
  background-color: white;
   width: 100%;
   margin: 0;
 /* padding: 15px; */    
height:auto;


  
  visibility: visible !important;
}

}
    </style>